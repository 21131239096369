import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-title',
  standalone: true,
  imports: [NgClass, BreadcrumbComponent],
  templateUrl: './title.component.html',
  styleUrl: './title.component.scss',
})
export class TitleComponent {
  @Input() title: string = '';
  @Input() titleContainer: string = 'container pt-3 pt-lg-4';
  @Input() subtitle: string = '';
  @Input() modal: boolean = false;
  @Input() breadcrumb: boolean = false;
  @Input() description: string = '';
}
