<div
  class="card border-0 shadow-sm"
  [@fadeIn]
  [ngClass]="image || icon ? 'card-margin' : 'h-100'"
>
  @if (hasImage) {
  <!--  -->
  @if (image || icon) {
  <div class="card-header border-0 bg-transparent">
    @if (image) {
    <img [src]="image" alt="Logo" />
    } @else if ( icon ) {
    <i [ngClass]="icon"></i>
    }
  </div>
  }
  <!--  -->
  }

  <div class="card-body">
    <ng-content #name></ng-content>
  </div>

  @if (primary || secondary) {
  <div class="card-footer p-0">
    <div class="d-flex w-100">
      @if (extra) {
      <div class="col px-0">
        <button
          type="button"
          class="btn btn-dark left-btn"
          (click)="onExtra.emit()"
        >
          <mat-icon>{{ extra }}</mat-icon>
        </button>
      </div>
      } @if (secondary) {
      <div class="col px-0">
        <button
          type="button"
          class="btn btn-secondary"
          [ngClass]="primary ? (extra ? 'rounded-0 w-100' : 'left-btn') : 'full-btn'"
          (click)="onSecondary.emit()"
        >
          <mat-icon>{{ secondary }}</mat-icon>
        </button>
      </div>
      } @if (primary) {
      <div class="col px-0">
        <button
          type="button"
          class="btn btn-primary"
          [ngClass]="secondary ? 'right-btn' : 'full-btn'"
          (click)="onPrimary.emit()"
        >
          <mat-icon>{{ primary }}</mat-icon>
        </button>
      </div>
      }
    </div>
  </div>
  }
</div>
