import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private loggedInSubject = new BehaviorSubject<boolean>(false);
  loggedIn$: Observable<boolean> = this.loggedInSubject.asObservable();

  constructor(private storage: LocalStorageService, private router: Router) {}

  get logged(): boolean {
    const token = this.getToken();

    return token && token.length > 0 ? true : false;
  }

  // TOKEN KEY
  getToken(): string {
    return this.storage.get<string>('token') ?? '';
  }

  setToken(token: string) {
    this.storage.set('token', token);
    this.loggedInSubject.next(true);
  }

  clearSession() {
    this.setToken('');
    this.loggedInSubject.next(false);
    this.storage.cleanStorage();
    this.router.navigate(['/']);
  }
}
