import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '@services/api.service';
import { LoadingService } from '@services/loading.service';
import { FormAlert } from '../alert/alert.component';
import { MessageService } from 'primeng/api';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-form',
  standalone: true,
  imports: [],
  template: '',
})
export class FormComponent {
  endpoint: string = '';
  method: string = 'post';
  alertData: FormAlert | null = null;
  data: any = {};
  form: any;
  services: Observable<any>[] = [];
  initialized: boolean = false;
  loading: boolean = false;

  constructor(
    protected api: ApiService,
    protected loadingService: LoadingService,
    protected formBuilder: FormBuilder,
    private messageService: MessageService
  ) {
    this.constructorSettings();
  }

  constructorSettings(): void {}

  isValid(): boolean {
    return true;
  }

  getParams() {
    return this.data;
  }

  submit() {
    if (this.loadingService.getLoading() || !this.isValid()) return;

    this.startLoading();

    const params = this.getParams();

    this.api.call(this.endpoint, this.method, params).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.success(response);
        } else {
          this.failed(response.message.join(', '));
        }
      },
      error: (error) => {
        this.failed(error);
      },
    });
  }

  success(response: any) {
    this.stopLoading();
  }

  failed(error: any) {
    this.handleAlert(
      typeof error === 'string'
        ? error || 'An error occurred!'
        : 'An error occurred!'
    );

    this.stopLoading();
  }

  getServices() {
    return this.services;
  }

  loadServices() {
    const services = !this.initialized
      ? [...this.services]
      : [...this.getServices()];

    if (this.services.length > 0) {
      this.startLoading();

      forkJoin(services).subscribe({
        next: (data) => {
          this.onLoadServicesSuccess(data);
          this.initialized = true;
        },
        error: (error) => {
          console.error(error);
          this.onLoadServicesError(error);
        },
      });
    }
  }

  onLoadServicesSuccess(responses: any[]): void {
    this.stopLoading();
  }

  onLoadServicesError(errors: any[]): void {
    this.stopLoading();
  }

  handleAlert(message: string, type: string = 'danger', title: string = '') {
    let toastr: any = {};

    switch (type) {
      case 'success':
        toastr = {
          severity: 'success',
          summary: title,
          detail: message,
        };

        break;

      case 'info':
        toastr = {
          severity: 'info',
          summary: title,
          detail: message,
        };

        break;

      case 'warning':
        toastr = {
          severity: 'warn',
          summary: title,
          detail: message,
        };

        break;

      case 'danger':
        toastr = {
          severity: 'error',
          summary: title,
          detail: message,
        };

        break;

      default:
        toastr = {
          severity: 'contrast',
          summary: title,
          detail: message,
        };

        break;
    }

    this.messageService.add(toastr);
  }

  startLoading() {
    this.loading = true;
    this.loadingService.start();
  }

  stopLoading() {
    this.loading = false;
    this.loadingService.stop();
  }
}
