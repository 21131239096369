import { Inject, Injectable } from '@angular/core';
import { environment } from '@environments';

import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

const PKEY = environment.storage + '_token';

export class Variable {
  key: string = '';
  value: any;
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  get<T>(key: string): T | null {
    const data = this.storage.get(PKEY) ?? {};

    return (data[key] as T) ?? null;
  }

  set(key: string, value: any): void {
    const data = this.storage.get(PKEY) ?? {};
    data[key] = value;
    this.storage.set(PKEY, data);
  }

  getChild<T>(key: string, childKey: string): T | null {
    const data: any = this.get(key);
    return data ? data[childKey] ?? null : null;
  }

  setChild<T>(key: string, child: Variable): void {
    const data: any = this.get(key) ?? {};
    data[child.key] = child.value;
    this.set(key, data);
  }

  remove(key: string): void {
    this.storage.remove(PKEY);
  }

  clearAll(): void {
    this.storage.clear();
  }

  cleanStorage(key?: string): void {
    if (key) {
      this.storage.remove(key);
      return;
    }

    this.storage.remove(PKEY);
  }
}
