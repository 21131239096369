<app-main-modal
  [title]="title"
  headerClass="bg-danger text-white"
  (close)="close()"
>
  <div class="row py-4" content>
    <div class="col-12 mb-4">
      <h5 [innerHTML]="message"></h5>
    </div>
  </div>

  <div class="w-100 m-0" footer>
    <div class="row">
      @if (hasCancel) {
      <div class="col-6 px-0 h-100">
        <button
          type="button"
          class="btn left-btn"
          [ngClass]="cancelClass"
          (click)="close()"
          (keyup.enter)="close()"
          [attr.aria-label]="cancelBtn"
        >
          {{ cancelBtn }}
        </button>
      </div>
      }

      <div class="col-6 px-0 h-100">
        <button
          type="button"
          class="btn"
          [ngClass]="confirmClass"
          [ngClass]="[confirmClass, hasCancel ? 'right-btn' : 'full-btn']"
          (click)="confirm()"
          (keyup.enter)="close()"
          [attr.aria-label]="confirmBtn"
        >
          {{ confirmBtn }}
        </button>
      </div>
    </div>
  </div>
</app-main-modal>
