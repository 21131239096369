import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { environment } from '@environments';

import { Observable, filter, map } from 'rxjs';

class Response<T> {
  data: T | undefined;
  status!: number;
  code!: number;
  message: string[] = [];
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private user: UserService) {}

  private built(service: string): string {
    return environment.endpoint + service;
  }

  private parse<T>(data: any): Response<T> {
    let response = new Response<T>();

    response.status = data.status;
    response.message = data.message;
    response.data = data.data as T | undefined;

    return response;
  }

  external<T = any>(
    service: string,
    method = 'get',
    params: any = {}
  ): Observable<Response<T>> {
    let request$: Observable<any>;

    switch (method) {
      case 'post':
        request$ = this.http.post(service, params);
        break;
      case 'put':
        request$ = this.http.put(service, params);
        break;
      case 'delete':
        request$ = this.http.delete(service, params);
        break;
      default:
        request$ = this.http.get(service, params);
        break;
    }

    return request$.pipe(map((data: any) => this.parse(data)));
  }

  call<T = any>(
    service: string,
    method = 'get',
    params: any = {}
  ): Observable<Response<T>> {
    const filteredParams =
      method === 'get'
        ? Object.fromEntries(
            Object.entries({ ...params }).filter(
              ([_, value]) =>
                value !== '' &&
                value !== null &&
                value !== undefined &&
                value !== -1
            )
          )
        : params;

    const serviceParams =
      method === 'get' ? { params: filteredParams } : filteredParams;

    let request$: Observable<any>;

    switch (method) {
      case 'post':
        request$ = this.http.post(this.built(service), serviceParams);
        break;
      case 'put':
        request$ = this.http.put(this.built(service), serviceParams);
        break;
      case 'delete':
        request$ = this.http.delete(this.built(service));
        break;
      default:
        request$ = this.http.get(this.built(service), serviceParams);
        break;
    }

    return request$.pipe(map((data: any) => this.parse(data)));
  }

  open(service: string, finish: (res: boolean) => void, isFull = false) {
    let url = service;

    if (!isFull) {
      url = this.built(service);
    }

    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };

    this.http.get<HttpResponse<any>>(url, options).subscribe({
      next: (response: any) => {
        if (response.body.type == 'application/json') {
          let reader = new FileReader();

          reader.addEventListener('loadend', (e: any) => {
            let json = JSON.parse(e.srcElement.result);
            alert(json.message.join('\n'));
          });

          reader.readAsText(response.body);

          return;
        }

        let hdrs = response.headers.get('Content-Disposition');
        let file = 'document';
        if (hdrs != null) {
          const split = hdrs.split('filename=');

          if (split.length > 0) {
            file = split[1].split('"').join('');
          }
        }

        const u = window.URL.createObjectURL(response.body);
        finish(true);
        window.open(u, '_blank');
      },
      error: (error: any) => {
        console.error(error);
        finish(false);
      },
    });
  }

  downloadSimple(service: string) {
    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };

    this.http
      .get<HttpResponse<any>>(this.built(service), options)
      .subscribe((response: any) => {
        if (response.body.type == 'application/json') {
          let reader = new FileReader();

          reader.addEventListener('loadend', (e: any) => {
            let json = JSON.parse(e.srcElement.result);
            alert(json.message.join('\n'));
          });

          reader.readAsText(response.body);

          return;
        }

        let hdrs = response.headers.get('Content-Disposition');
        let file = 'document';
        if (hdrs != null) {
          const split = hdrs.split('filename=');
          if (split.length > 0) {
            file = split[1].split('"').join('');
          }
        }

        const u = window.URL.createObjectURL(response.body);
        const l = document.createElement('a');
        l.href = u;
        l.download = file;
        l.click();
      });
  }

  download(
    service: string,
    finish: (exit: boolean) => void,
    isFull = false,
    name = 'document.pdf',
    fileType = 'pdf'
  ) {
    let url = service;

    if (!isFull) {
      url = this.built(service);
    }

    const options = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json',
    };

    this.http.get<HttpResponse<any>>(url, options).subscribe({
      next: (response: any) => {
        if (response.body.type == 'application/json') {
          let reader = new FileReader();

          reader.addEventListener('loadend', (e: any) => {
            let json = JSON.parse(e.srcElement.result);
            alert(json.message.join('\n'));
          });

          reader.readAsText(response.body);

          finish(false);

          return;
        }

        let hdrs = response.headers.get('Content-Disposition');
        let file = 'document';
        if (hdrs != null) {
          let split = hdrs.split('filename=');

          if (split.length > 0) {
            file = split[1].split('"').join('');
          }
        }

        file += '.' + fileType;

        let u = window.URL.createObjectURL(response.body);
        let l = document.createElement('a');
        l.href = u;
        l.download = name.includes('.') ? name : name + '.' + fileType;
        l.click();
        finish(true);
      },
      error: (error: any) => {
        console.error(error);

        let errorMsg = error.error.message
          ? error.error.message
          : error.message;
        alert(errorMsg);
        finish(false);
      },
    });
  }
}
