<div class="modal-header" role="heading" [ngClass]="headerClass">
  <div class="d-flex align-items-center w-100">
    <h5 class="modal-title me-auto">{{ title }}</h5>

    <i
      class="fa-solid fa-xmark fa-xl clickable"
      role="button"
      aria-label="Close modal"
      (click)="onClose()"
      (keyup.enter)="onClose()"
    ></i>
  </div>
</div>

<div class="modal-body">
  <ng-content select="[content]"></ng-content>
</div>

<div class="modal-footer">
  <ng-content select="[footer]"></ng-content>
</div>
