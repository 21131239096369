import { NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lazy-image',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './lazy-image.component.html',
  styleUrls: ['./lazy-image.component.scss'],
})
export class LazyImageComponent {
  protected loading: boolean = false;

  @Input() alt: string = '';
  @Input() default: string = '';
  @Input() imageUrl: string = '';
  @Input() imageClass: string = '';

  handleImageError() {
    this.imageUrl = this.default; // Reemplaza con la ruta de tu imagen de respaldo.
  }

  onLoad() {
    this.loading = true;
  }
}
